import React, { useEffect, useState } from 'react';
import './App.css';
import { TVChartContainer } from './components/TVChartContainer/index';
import { getAllMarketIndices } from './components/TVChartContainer/api/helpers';
import { SocktConnect } from './components/TVChartContainer/socket';
import axios from 'axios';
const marketExchangeName = { 1: "NSE_EQ", 3: "BSE_EQ", 2: "NSE_FO", 8: "NSE_OPT", 13: "NSE_CUR" };  //chartExchange from GlobalState
const segmentsName = { "NSE_EQ": "NSE Equity", "BSE_EQ": "BSE Equity", "NSE_FO": "NSE Future and Option", "NSE_CUR": "CDS", "MUTUAL_FUND": "Mutual Fund" }; //segmentsName from GlobalState
const App = () => {
	const [stockDetails, setStockDetails] = useState("");
	const [holidayListJson, setHolidayListJson] = useState(null);
	const getAllMarketIndicesData = async (paramArray) => {
		try {
			const allMarketIndices = await getAllMarketIndices(paramArray.authToken, paramArray?.access_token, paramArray.chartUrl);
			if (allMarketIndices?.length > 0) {
				const updatedParamArray = {
					...paramArray,
					"allIndices": allMarketIndices
				};
				setStockDetails(updatedParamArray);
			} else {
				setStockDetails(paramArray);
			}
		} catch (e) {
			setStockDetails(paramArray);
			// console.error('Error in callHoldingSymbolApi:', e);
		}
	}

	const getHolidayData = async () => {
		const result = await axios.get("https://stoxbox.s3.ap-south-1.amazonaws.com/appv2comp/chart_holiday_master.json?clear=cache");
		if (
			result.status == 200 &&
			Object.keys(result.data).length > 0 &&
			Object.keys(result.data) !== undefined
		) { 
			setHolidayListJson(result.data);
		}
	};


	useEffect(() => {
		const getQueryParams = () => {
			const queryParams = new URLSearchParams(window.location.search);
			const paramValue = queryParams.get('parameter');
			if (paramValue) {
				try {
					const decodedParamValue = atob(paramValue);
					const paramArray = JSON.parse(decodedParamValue);
					SocktConnect(paramArray.userId);
					getAllMarketIndicesData(paramArray); 
				} catch (error) {
					console.error('Error decoding base64 parameter:', error);
				}
			}
		};
		getHolidayData();
		getQueryParams();
	}, []);

	return (
		<div className={'App'}>
			{stockDetails && holidayListJson &&
				<TVChartContainer stockDetails={stockDetails} marketExchangeName={marketExchangeName} segmentsName={segmentsName} holidayListJson={holidayListJson}/>
			}
		</div>
	);
};

export default App;
