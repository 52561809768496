import { publish } from "../api/dataStream";

var objSocket;
var Global = {};
let userId = '';
Global.Constants = {};
Global.Constants.C_S_CHANNEL_INTERACTIVE = "Interactive";
Global.Constants.C_S_ON = "ON";
Global.Constants.C_S_CHAR0 = "\u0000";
Global.Constants.C_S_CHAR2 = "\u0002";
var _selfSocCom = {};
_selfSocCom.SocketChannelId = "Broadcast";
_selfSocCom.CompressStatus = "ON";

const ConnectSocket = (ip, port, mode, socketUser, listner) => {

  const mobilenumber = localStorage.getItem("user_mobile_number");
  const ucc = socketUser;

  if (ucc !== '') {
    userId = ucc.substring(ucc.length - 9);
  } else {
    userId = mobilenumber.substring(mobilenumber.length - 9);
  }

  userId += makeid(5);
  if(userId.length >= 10){
    userId = userId.substring(userId.length-9);
  }
  userId = 'C' + userId.padStart(9, '#');

  objSocket = null;
  objSocket = new WebSocket(mode + "://" + ip + ":" + port);

  objSocket.binaryType = "arraybuffer";

  objSocket.onopen = socketOnOpen;
  objSocket.onclose = socketOnClose;
  objSocket.onerror = socketOnError;
  objSocket.onmessage = listner;
};

var socketOnOpen = function () {
  var sUsedId = userId;

  var sApiKey = "";

  var sLoginRequest = "";
  if (sApiKey == "") {
    sLoginRequest =
      "63=FT3.0|64=101|65=74|66=14:59:22|67=" +
      sUsedId +
      "|68=|4=|400=0|401=1|396=HO|51=4|395=127.0.0.1";
  } else {
    sLoginRequest =
      "63=FT3.0|64=101|65=74|66=14:59:22|67=" +
      sUsedId +
      "|68=" +
      sApiKey +
      "|4=|400=0|401=2|396=HO|51=4|395=127.0.0.1";
  }
  SendMessageOnSocket(sLoginRequest);
};

function SendMessageOnSocket(msg) {
  try {
    if (objSocket.readyState == 1) {
      objSocket.send(fragmentData(msg));

    }

  }
  catch (ex) {

  }
}

function fragmentData(_requestPacket) {
  // console.log('_requestPacket :', _requestPacket);
  try {

    var _strHead = _strHead = String.fromCharCode('5'); //5 comprression char


    var i;
    var _data = new ArrayBuffer(_strHead.length);
    var _headerBytes = new Uint8Array(_data);

    for (i = 0; i < _strHead.length; i += 1) {
      _headerBytes[i] = _strHead.charCodeAt(i);
    }
    var _baRequest;
    _baRequest = HandleCompressedData(_requestPacket);


    var _length = _baRequest.length + 4;

    var _lenLength = _length.toString().length;
    var _lengthString = "";

    for (i = 0; i < (5 - _lenLength); i++) {
      _lengthString += "0";
    }

    _lengthString += _length.toString();

    _data = new ArrayBuffer(_lengthString.length);
    var _lenBytes = new Uint8Array(_data);

    for (i = 0; i < _lengthString.length; i += 1) {
      _lenBytes[i] = _lengthString.charCodeAt(i);
    }

    var _baActualSend = new Uint8Array(5 + _length);
    _baActualSend.set(_lenBytes);
    _baActualSend.set(_baRequest, 5);

    var _outputStream = new Uint8Array(_headerBytes.length + _baActualSend.length);
    _outputStream.set(_headerBytes);
    _outputStream.set(_baActualSend, 1);
    return _outputStream.buffer;

  }
  catch (e) {

  }
};


AddHTTPHeader = function (_requestPacket) {
  try {
    if (typeof ArrayBuffer == "undefined") {
      if (
        _selfSocCom.SocketChannelId !== Global.Constants.C_S_CHANNEL_INTERACTIVE
      ) {
        var strHead = String.fromCharCode("2");
        var length = _requestPacket.length;
        var lenLength = length.toString().length;
        var LengthString = "";
        for (i = 0; i < 5 - lenLength; i++) {
          LengthString += "0";
        }
        LengthString += length.toString();
        _requestPacket = strHead + LengthString + _requestPacket;
        return _requestPacket;
      } else {
        var strHead = String.fromCharCode("2");
        _requestPacket = strHead + _requestPacket;
        return _requestPacket;
      }
    } else {
      var _strHead = String.fromCharCode("2"); //No compression
      if (_selfSocCom.CompressStatus == Global.Constants.C_S_ON)
        _strHead = String.fromCharCode("5"); //5 comprression char

      var i;
      var _data = new ArrayBuffer(_strHead.length);
      var _headerBytes = new Uint8Array(_data);

      for (i = 0; i < _strHead.length; i += 1) {
        _headerBytes[i] = _strHead.charCodeAt(i);
      }
      var _baRequest;
      if (_selfSocCom.CompressStatus == Global.Constants.C_S_ON)
        _baRequest = HandleCompressedData(_requestPacket);
      else _baRequest = HandleConvertToByteArray(_requestPacket);

      var _length = _baRequest.length;
      if (
        _selfSocCom.SocketChannelId !== Global.Constants.C_S_CHANNEL_INTERACTIVE
      )
        _length += 4;
      var _lenLength = _length.toString().length;
      var _lengthString = "";

      for (i = 0; i < 5 - _lenLength; i++) {
        _lengthString += "0";
      }

      _lengthString += _length.toString();

      _data = new ArrayBuffer(_lengthString.length);
      var _lenBytes = new Uint8Array(_data);

      for (i = 0; i < _lengthString.length; i += 1) {
        _lenBytes[i] = _lengthString.charCodeAt(i);
      }

      var _baActualSend = new Uint8Array(5 + _length);
      _baActualSend.set(_lenBytes);
      _baActualSend.set(_baRequest, 5);

      var _outputStream = new Uint8Array(
        _headerBytes.length + _baActualSend.length
      );
      _outputStream.set(_headerBytes);
      _outputStream.set(_baActualSend, 1);
      return _outputStream.buffer;
    }
  } catch (e) { }
};

HandleCompressedData = function (_rawData) {
  try {
    var _data = new ArrayBuffer(_rawData.length);
    var _uint8buf = new Uint8Array(_data);
    for (var i = 0; i < _rawData.length; i += 1) {
      _uint8buf[i] = _rawData.charCodeAt(i) & 0xFF;
    }
    var _compData = Zlib.compress(new Uint8Array(_data), 6);
    return _compData;
  } catch (e) { }
};

HandleConvertToByteArray = function (_data) {
  try {
    var _arrbufData = new ArrayBuffer(_data.length);
    var _uint8buf = new Uint8Array(_arrbufData);
    for (var i = 0; i < _data.length; i += 1) {
      _uint8buf[i] = _data.charCodeAt(i) & 0xff;
    }
    var _baData = new Uint8Array(_arrbufData);
    return _baData;
  } catch (e) { }
};

var socketOnError = function (error) {
  objSocket.onopen = null;
  objSocket.onclose = null;
  objSocket.onerror = null;
  objSocket.onmessage = null;
  objSocket = null;
};

var socketOnClose = function (event) {
  try {
    var reason;
    if (event.code == 1000)
      reason =
        "Normal closure, meaning that the purpose for which the connection was established has been fulfilled.";
    else if (event.code == 1001)
      reason =
        'An endpoint is "going away", such as a server going down or a browser having navigated away from a page.';
    else if (event.code == 1002)
      reason =
        "An endpoint is terminating the connection due to a protocol error";
    else if (event.code == 1003)
      reason =
        "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
    else if (event.code == 1004)
      reason = "Reserved. The specific meaning might be defined in the future.";
    else if (event.code == 1005)
      reason = "No status code was actually present.";
    else if (event.code == 1006)
      reason =
        "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
    else if (event.code == 1007)
      reason =
        "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [http://tools.ietf.org/html/rfc3629] data within a text message).";
    else if (event.code == 1008)
      reason =
        'An endpoint is terminating the connection because it has received a message that "violates its policy". This reason is given either if there is no other suitable reason, or if there is a need to hide specific details about the policy.';
    else if (event.code == 1009)
      reason =
        "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
    else if (event.code == 1010)
      // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
      reason =
        "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: " +
        event.reason;
    else if (event.code == 1011)
      reason =
        "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
    else if (event.code == 1015)
      reason =
        "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
    else reason = "Unknown reason";

  } catch (ex) {
    // console.log(
    //   "Failed in close event. Reason : " +
    //   ex.message +
    //   " | Stack trace : " +
    //   ex.stack
    // );
  }
};

var baOldData = null;

OnSocketDataReceive = function (_event) {

  try {
    var baProcessData = null;
    var intRawPktLen;
    var intCompLen = 0;
    var _response,
      isBroken = false;
    var totalPacketLength = 0;

    if (_event.data instanceof ArrayBuffer) {
      var dataReceived = null;
      var dataPacketLengthList = [];
      if (baOldData == null) dataReceived = new Uint8Array(_event.data);
      else {
        dataReceived = AppendOrCopyBuffer(baOldData, _event.data);
        baOldData = null;
      }

      intRawPktLen = dataReceived.byteLength;
      var i = 0;
      if (intRawPktLen > 5) {
        while (i < intRawPktLen) {
          if (dataReceived[i] == 5 || dataReceived[i] == 2) {
            var strPacketLength = String.fromCharCode.apply(
              null,
              dataReceived.subarray(i + 1, i + 6)
            );
            if (strPacketLength.length == 5) {
              var packetLength = parseInt(strPacketLength, 10);
              dataPacketLengthList.push(packetLength + 6);
              totalPacketLength += packetLength + 6;
              i = i + 6 + packetLength;
            } else {
              baOldData = dataReceived.subarray(i, intRawPktLen);
              isBroken = true;
              break;
            }
          } else {
            // alert()
          };
        }
      } else baOldData = dataReceived;

      if (intRawPktLen == totalPacketLength) {
        // split and pass to zlib to uncompress
        for (
          var i = 0,
          j = 0,
          k = dataPacketLengthList[0],
          len = dataPacketLengthList.length;
          i < len;
          i++
        ) {
          var uncompData = dataReceived.subarray(j, k);
          ProcessSocketMessage(uncompData);

          j = k;
          k = k + dataPacketLengthList[i + 1];
        }
        baOldData = null;
      } else {
        var i = 0,
          j = 0;
        var k = dataPacketLengthList.length > 0 ? dataPacketLengthList[0] : 0;

        if (!isBroken) {
          for (var len = dataPacketLengthList.length; i < len - 1; i++) {
            var uncompData = dataReceived.subarray(j, k);
            ProcessSocketMessage(uncompData);

            j = k;
            k = k + dataPacketLengthList[i + 1];
          }

          if (i == dataPacketLengthList.length - 1) {
            if (dataReceived.subarray(j, k)[0] != 5) alert();

            baOldData = null;
            baOldData = dataReceived.subarray(j, k);
            if (baOldData[0] != 5 && baOldData[0] != 2) alert();
          } else {
            alert()
          };
        } else {
          for (var len = dataPacketLengthList.length; i < len; i++) {
            var uncompData = dataReceived.subarray(j, k);
            ProcessSocketMessage(uncompData);

            j = k;
            k = k + dataPacketLengthList[i + 1];
          }
          isBroken = false;
        }
      }

    } else ProcessPacketString(_event.data);

  } catch (e) { }

};

AppendOrCopyBuffer = function (buffer1, buffer2) {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp;
};

ProcessSocketMessage = function (uncompData) {
  var _response = "";

  _response = DeCompressData(uncompData);
  if (_response == undefined) {
    console.log(_response);
  }
  //remove End of response char
  var intTmtrIndex = _response.indexOf('\u0000');
  if (intTmtrIndex != -1) {
    _response = _response.substr(0, intTmtrIndex);
  }

  //split multi response packet with Start of response char C_S_CHAR2  
  var arrData = _response.split("\u0002");
  var intDataCount = arrData.length;

  for (var intDataCntr = 0; intDataCntr < intDataCount; intDataCntr++) {
    if (arrData[intDataCntr] != "") {
      const data = arrData[intDataCntr];
      try {
        // const divisor = 1;
        const divisorCheck = /\|399=[0-9_\$]*/;
       
        const divisor = (divisorCheck.exec(data)[0].split('=')[1]); 
        // const divisor = 100;
        const segIdRegex = /\|1=[0-9_\$]*/;
        const securityCodeRegex = /\|7=[0-9_\$]*/;
        const lastTradedPriceRegex = /\|8=[0-9_\$]*/;
        const openPriceRegex = /\|75=[0-9_\$]*/;
        const closePriceRegex = /\|76=[0-9_\$]*/;
        const volumeRegex = /\|79=[0-9_\$]*/;
        const highPriceRegex = /\|77=[0-9_\$]*/;
        const lowPriceRegex = /\|78=[0-9_\$]*/;
        const perChangeRegx = /393=([-+]?(\d+(\.\d+)?|\.\d+))/;
        const persantChanageRegx = /54=([-+]?(\d+(\.\d+)?|\.\d+))/;
        const timeRegex = /74=[0-9- ]*/;


        if (data != null && data.length > 0) {
          if (data.indexOf("=") > -1) {
            var packatTypeRegEx = /\|70=[0-9_\$]*/;
            if (packatTypeRegEx.exec(data) != null) {
              const outputValue = packatTypeRegEx.exec(data)[0].split('=')[1];
              //Ignore Login Packet
              if (outputValue == 10000) {
                continue;
              }
            }
            //Check if packet is not proper
            //Write code to check nessasary data. 
          }
        }
        let lastTradedPrice = checkValidOutputForLastTradePrice(lastTradedPriceRegex,data,divisor);
        const segmentID = checkValidOutput(segIdRegex, data, 0);
        const securityID = checkValidOutput(securityCodeRegex, data, 0);
        // let lastTradedPrice = checkValidOutput(lastTradedPriceRegex, data, divisor);
        const openPrice = checkValidOutput(openPriceRegex, data, divisor);
        let prevClosePrice = checkValidOutput(closePriceRegex, data, divisor);
        const volumeTradedToday = checkValidOutput(volumeRegex, data, 0);
        const highPrice = checkValidOutput(highPriceRegex, data, divisor);
        const lowPrice = checkValidOutput(lowPriceRegex, data, divisor);
        let perChangeValue = checkValidOutput(perChangeRegx, data, 0);
        let persantChanageValue = checkValidOutput(persantChanageRegx, data, 0);
        const date = timeRegex.exec(data)[0].split('=')[1];

        if (lastTradedPrice == 0 && prevClosePrice > 0) {
          lastTradedPrice = prevClosePrice;
          if (persantChanageValue == 100 || persantChanageValue == -100) {
            perChangeValue = persantChanageValue = 0;
          }
        }


        publish(segmentID + '_' + securityID, {
          DT: date,
          O: openPrice,
          C: prevClosePrice,
          H: highPrice,
          L: lowPrice,
          V: volumeTradedToday,
          LTP: lastTradedPrice,
        });

      } catch (e) {
        // console.log("error from getting data from socket", e);
      }
    }
  }
};

ProcessPacketString = function (data) {
  // console.log("Data From Server : " + data);
};

DeCompressData = function (_pktData) {
  try {
    var _compData = new Uint8Array(_pktData);
    //first 6 bytes will be special char and length of data
    //so need to take after it
    _compData = _compData.subarray(6, _compData.length);
    var _uncompData = Zlib.uncompress(new Uint8Array(_compData));
    var _sResp = [];
    for (var i = 0, len = _uncompData.length; i < len; i += 1) {
      _sResp.push(String.fromCharCode(_uncompData[i]));
    }
    return _sResp.join("");
  } catch (e) { }
};

HandleNormalData = function (_plainData) {
  try {
    var data = new Uint8Array(_plainData);
    var sResp = [];
    for (var i = 0; i < data.length; i += 1) {
      sResp.push(String.fromCharCode(data[i]));
    }
    return sResp.join("");
  } catch (e) { }
};

const checkValidOutput = (regx, data, divisor) => {
  let outputValue = 0;

  if (data != null && data.length > 0) {
    if (data.indexOf("=") > -1) {
      try {
        outputValue = regx.exec(data)[0].split('=')[1]
      } catch (e) {
      }
      if (divisor > 0) {
        outputValue = outputValue / divisor;
      }
    }
  }
  return outputValue;
}

const checkValidOutputForLastTradePrice = (regx,data,divisor) => {
  let outputValue = 0;

  if(data != null && data.length > 0){
    if(data.indexOf("=") > -1){
      try{

        outputValue = regx.exec(data)[0].split('=')[1]
      }catch (e){
        // console.log("eeeee",e);
        // console.log("data",data);
      }
      if(divisor > 0){
        outputValue = outputValue/divisor;
      }
    } 
  }

  if (divisor > 100) {
    outputValue = outputValue.toFixed(4);
  } else {
    outputValue = outputValue.toFixed(2);
  }


  return outputValue;
}


const makeid = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export { ConnectSocket, SendMessageOnSocket};
