import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { widget } from '../../charting_library/';
import Datafeed from './api/datafeed';
import { getSavedIndicators, getSavedTemplate, saveIndicators, saveTemplate } from './api/helpers';



export function TVChartContainer(props) {
	const { stockDetails, symbol, interval, libraryPath, fullscreen, autosize, marketExchangeName, segmentsName, holidayListJson } = props;
	const ref = useRef(null);
	let tvWidget = useRef(null);
	// const [saveTemplateFlag, setSaveTemplateFlag] = useState(false);
	let saveTemplateFlag = false;
	const savedResolution = localStorage.getItem('lastSelectedResolution');
	const equetyHolidayList = holidayListJson?.equityHolidayList;
	const currencyHolidayList = holidayListJson?.currencyHolidayList;
	const derivativeHolidayList = holidayListJson?.derivativeHolidayList;

	// const saveChartTemplate = () => {
	// 	tvWidget.current.save(async (chartTemplate) => {
	// 		const result = await saveTemplate(chartTemplate, stockDetails.authToken, stockDetails.chartUrl);
	// 		if (result) {
	// 			alert(result);
	// 		}
	// 		// localStorage.setItem('chartTemplate', JSON.stringify(chartTemplate));
	// 	});
	// };

	const convertHolidayDate = (e) => {
		var t = e.getMonth() + 1
			, n = e.getDate();
		return t = t > 9 ? t : "0".concat(t),
			n = n > 9 ? n : "0".concat(n),
			"".concat(e.getFullYear()).concat(t).concat(n)
	}

	const convertHolidayList = (marketSegment) => {
		const markerRules = getMarketRuleByMs(marketSegment);
		return markerRules.filter((function (entry) {
			return !entry.dayOfWeek && !entry.open && !entry.close;
		})).map((function (entry) {
			if (entry.date) {
				return convertHolidayDate(new Date(entry.date));
			}
		})).filter((function (entry) {
			return Boolean(entry);
		})).join(",");
	};


	const getHolidaysWithSession = (mktSegID) => {
		var marketRules = getMarketRuleByMs(mktSegID);

		var holidaysWithSession = marketRules
			.filter(function (rule) {
				return !rule.dayOfWeek && rule.date;
			})
			.map(function (rule) {
				if (rule.open && rule.close && rule.date) {
					var date = new Date(rule.date);
					var openTime = rule.open.replace(":", "");
					var closeTime = rule.close.replace(":", "");
					return "".concat(openTime, "-").concat(closeTime, ":").concat(convertHolidayDate(date));
				}
			})
			.filter(function (entry) {
				return Boolean(entry);
			})
			.join(";");

		var defaultRules = getMarketRuleByMs(mktSegID);

		var defaultHolidaysWithSession = getHolidaysWithSessionDefault(defaultRules);
		return holidaysWithSession + ";" + defaultHolidaysWithSession;
	}

	const getHolidaysWithSessionDefault = (rules) => {
		return rules
			.filter(function (rule) {
				return !rule.dayOfWeek && rule.date;
			})
			.map(function (rule) {
				if (rule.open && rule.close && rule.date) {
					var date = new Date(rule.date);
					var openTime = rule.open.replace(":", "");
					var closeTime = rule.close.replace(":", "");
					return "".concat(openTime, "-").concat(closeTime, ":").concat(convertHolidayDate(date));
				}
			})
			.filter(function (entry) {
				return Boolean(entry);
			})
			.join(";");
	}

	const getMarketRuleByMs = (mktSegID) => {
		var rules = mktSegID == 13 ? currencyHolidayList : (mktSegID == 2 ? derivativeHolidayList : equetyHolidayList);
		return rules;
	}

	const saveChartTemplateNeeded = async (data) => {
		// if (saveTemplateFlag) {
		// 	tvWidget.current.save(async (chartTemplate) => {
		// 		await saveTemplate(chartTemplate, stockDetails.authToken, stockDetails.chartUrl);
		// 	});
		// }
		const result = await saveIndicators(data, stockDetails.authToken, stockDetails.chartUrl);
		if (result) {
			console.log(result);
		}
	};

	// const loadChartTemplate = async () => {
	// 	// const chartTemplate = localStorage.getItem('chartTemplate');
	// 	const chartTemplate = await getSavedTemplate(stockDetails.authToken, stockDetails.chartUrl);
	// 	if (chartTemplate) {
	// 		tvWidget.current.load(chartTemplate);
	// 	}
	// 	saveTemplateFlag = true;
	// };

	useEffect(() => {

		const currentTime = new Date();
		const noonTime = new Date();
		noonTime.setHours(14, 0, 0, 0);
		let timePeriod = "0915-1530";
		if (stockDetails.MS == 13) {
			timePeriod = "0900-1700";
		}
		const url = new URL(stockDetails?.chartUrl);
		const chartTemplateUrl = `${url.protocol}//${url.host}/stxtemplate`;
		// let timePeriod = "0915-1400";
		// if(currentTime > noonTime){
		// 	timePeriod = "0915-1530";
		// }  

		const widgetOptions = {
			debug: false,
			// symbol: stockDetails?.S || symbol,
			symbol: stockDetails?.S + "#" + stockDetails?.MS + "_" + stockDetails?.T || symbol,
			datafeed: new Datafeed(stockDetails, marketExchangeName, segmentsName, convertHolidayList, getHolidaysWithSession),
			interval: savedResolution !== null ? savedResolution : interval,    // savedResolution from local storage
			container: ref.current,
			library_path: libraryPath,
			timezone: 'Asia/Kolkata',
			session: timePeriod,
			locale: 'en',
			// disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', 'header_compare'],
			// disabled_features: ['header_symbol_search', 'header_compare','header_fullscreen_button'],
			disabled_features: ['use_localstorage_for_settings', 'symbol_info', 'header_fullscreen_button','header_symbol_search'],
			// disabled_features : ['header_symbol_search', 'header_compare',"link_to_tradingview", "use_localstorage_for_settings", "header_interval_dialog_button", "symbol_info", "countdown", "chart_property_page_trading", "header_fullscreen_button"],
			enabledFeatures: true,
			enabled_features: ["keep_left_toolbar_visible_on_small_screens", "header_in_fullscreen_mode", "side_toolbar_in_fullscreen_mode"],
			custom_css_url: "/assets/chart-style.css",
			charts_storage_url: chartTemplateUrl,
			charts_storage_api_version: props.chartsStorageApiVersion,
			client_id: props.clientId,
			user_id: stockDetails?.userId,
			fullscreen: fullscreen,
			// theme: 'dark',
			// overrides: {
			// 	// 'mainSeriesProperties.candleStyle.drawWick': false,
			// 	'mainSeriesProperties.candleStyle.drawCandleTail': false,
			// 	// 'paneProperties.crossHairProperties.vertLineProperties.color': 'transparent', // Disable crosshair animation
			// 	// Other overrides
			// },
			autosize: autosize,
			auto_save_delay: 1,
		};

		tvWidget.current = new widget(widgetOptions);


		tvWidget.current.onChartReady(() => {
			// loadChartTemplate();
			tvWidget.current.headerReady().then(async () => {
				// loadChartTemplate();
				const savedStudiesResolution = await getSavedIndicators(stockDetails.authToken, stockDetails.chartUrl);
				let savedStudies = typeof savedStudiesResolution?.studies != 'undefined' ? savedStudiesResolution?.studies : [];

				const savedResolution = typeof savedStudiesResolution?.resolution != 'undefined' ? savedStudiesResolution?.resolution : "";
				if (savedResolution) {
					tvWidget.current.chart().setResolution(savedResolution);
				}

				const savedChartType = typeof savedStudiesResolution?.type != 'undefined' ? savedStudiesResolution?.type : "";
				if (savedChartType) {
					tvWidget.current.chart().setChartType(savedChartType);
				}

				// const button = tvWidget.current.createButton();
				// button.setAttribute("title", "Save Template");
				// button.textContent = "Save Template";
				// button.addEventListener("click", saveChartTemplate);
				// button.style.cursor = "pointer";

				// Remove the existing volume study
				const removeExistingVolumeStudy = () => {
					const allStudies = tvWidget.current.chart().getAllStudies();
					allStudies.forEach(study => {
						if (study.name === "Volume" || study.shortName === "VOL") {
							tvWidget.current.chart().removeEntity(study.id);
						}
					});
				};

				// Check if a volume study exists in the saved studies
				const volumeStudyExistsInSavedStudies = (savedStudies) => {
					return savedStudies.some(study => study.name === "Volume" || study.shortName === "VOL");
				};

				// Load studies from local storage
				// let savedStudies = JSON.parse(localStorage.getItem('savedStudies') || '[]');


				// If savedStudies is empty, force save the current studies into localStorage
				if (savedStudies.length === 0) {
					const currentStudies = tvWidget.current.chart().getAllStudies();
					// localStorage.setItem('savedStudies', JSON.stringify(currentStudies));
					savedStudies = currentStudies;
				}

				// Add all saved studies to the chart, except for volume
				savedStudies.forEach(study => {
					if (study.name !== "Volume" && study.name !== "Overlay") {
						tvWidget.current.chart().createStudy(study.name, study.pinned, study.visible, study.inputs, study.styles);
					}
				});

				// If the saved studies don't have a volume study, add one to the chart
				if (!volumeStudyExistsInSavedStudies(savedStudies)) {
					removeExistingVolumeStudy();  // Ensure only one volume study exists on the chart
					tvWidget.current.chart().createStudy("Volume", false, true);
				}

				// Save the current studies upon updates
				tvWidget.current.subscribe('onAutoSaveNeeded', function () {
					const allStudies = tvWidget.current.chart().getAllStudies();

					const currentResolution = tvWidget.current.chart().resolution();
					localStorage.setItem('lastSelectedResolution', currentResolution);

					const currentChartType = tvWidget.current.chart().chartType();
					// alert(currentChartType);
					saveChartTemplateNeeded({ studies: allStudies, resolution: currentResolution, type: currentChartType });
					// localStorage.setItem('savedStudies', JSON.stringify(allStudies));
				});
			});
		});
		return () => {
			if (tvWidget.current !== null) {
				tvWidget.current.remove();
				tvWidget.current = null;
			}
		};
	}, [stockDetails, symbol, interval, libraryPath, fullscreen, autosize, marketExchangeName, segmentsName]);

	return <div ref={ref} className={'TVChartContainer'} />;
}

TVChartContainer.defaultProps = {
	// datafeedUrl: 'https://demo_feed.tradingview.com',
	symbol: 'No Symbol',
	interval: '1',
	chartsStorageUrl: 'https://saveload.tradingview.com',
	chartsStorageApiVersion: 'template',
	clientId: 'Stoxbox.in',
	userId: "SMSTEST123456",
	// studiesOverrides: {},
	libraryPath: '/charting_library/',
	fullscreen: true,
	autosize: true,
};
