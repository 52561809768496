import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

// Create a subject for the pub/sub mechanism
const dataStream = new Subject();

// Object to store subscriptions and associated data
const subscriptions = {};

export const subscribe = (key, callback) => {
  if (!subscriptions[key]) {
    subscriptions[key] = dataStream.pipe(
      filter((data) => data.key === key)
    );
  }
  return subscriptions[key].subscribe((data) => callback(data.data));
};

export const chartSubscribe = (key, callback) => {
  const keyArray = key.split("@@");

  if (!subscriptions[key]) {
    subscriptions[key] = dataStream.pipe(
      filter((data) => data.key === keyArray[0])
    );
  }
  return subscriptions[key].subscribe((data) => callback(data.data));
};

export const chartUnsubscribe = (key, subscription) => {

  if (subscriptions[key]) {
    subscription.unsubscribe();
    delete subscriptions[key];
  }
};

export const unsubscribe = (key, subscription) => {
  if (subscriptions[key]) {
    subscription.unsubscribe();
    delete subscriptions[key];
  }
};

export const publish = (key, data) => {
  dataStream.next({ key, data });
};
