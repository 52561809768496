import { getSecurityInfo, getSymbolFromAPI, makeApiRequest, makeApiRequestForCorporateActions, makeApiRequestForIntraday } from './helpers.js';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';

let tempSearchResult = [];
let tempSelectedSymbolInfo;
const lastBarsCache = new Map();
const configurationData = {
	// supported_resolutions: ['1', '5', '5D', '1W', '1M', '6M'],
	supported_resolutions: ["1", "2", "3", "4", "5", "10", "15", "30", "1H", "2H", "3H", "1D", "1W", "1M"],
	// supported_resolutions: ["1", "5", "1H", "1D"],
	intraday_multipliers: ['1'],
	supports_marks: true
};

async function getAllSymbols(userInput, marketDataRef, segmentsNameRef, stockDetails) {
	const data = await getSymbolFromAPI(userInput, stockDetails.authToken, stockDetails.chartUrl);
	// console.log("data",data);

	const allSymbols = data.map(symbol => ({
		symbol: symbol.S,
		full_name: symbol.S + "#" + symbol.MS + "_" + symbol.T,
		description: symbol.SD + ' ' + symbol.SDD,
		exchange: segmentsNameRef[marketDataRef[symbol.MS]],
		type: symbol.Se,
		MS: symbol.MS,
		T: symbol.T,
		SD: symbol.SD,
		S: symbol.S,
		marketExchange: marketDataRef[symbol.MS],

	}));
	return allSymbols;
}



export default function Datafeed(stockDetails, marketData, segmentsName, convertHolidayList, getHolidaysWithSession) {

	const props = stockDetails;
	const marketDataRef = marketData;
	const segmentsNameRef = segmentsName;

	const onReady = (callback) => {
		setTimeout(() => callback(configurationData));
	};

	const searchSymbols = async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback
	) => {

		const symbols = await getAllSymbols(userInput, marketDataRef, segmentsNameRef, stockDetails);
		tempSearchResult = symbols;
		onResultReadyCallback(symbols);
	}

	const resolveSymbol = async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {

		const allIndices = props?.allIndices;
		let symbolItem;
		const newSymbols = tempSearchResult.filter((symbol) => {
			const isFullSymbolContainsInput = symbol.S + "#" + symbol.MS + "_" + symbol.T === symbolName;
			return isFullSymbolContainsInput;
		});

		if (newSymbols.length > 0) {
			const newSymbol = newSymbols[0];
			const tempEquety = await findEquetyFromCH(allIndices, newSymbol, marketDataRef, stockDetails);

			symbolItem = {
				full_name: newSymbol.S + "#" + newSymbol.MS + "_" + newSymbol.T,
				description: newSymbol.SD,
				exchange: newSymbol.exchange,
				marketSegment: newSymbol.MS,
				token: newSymbol.T,
				IC:tempEquety?.IC,
				// api_exchange: newSymbol.marketExchange,
				api_exchange: tempEquety?.equity,
				// temp_symbole: newSymbol.symbol,
				// symbolName: newSymbol.S
				user_id: stockDetails.userId,
				temp_symbole: tempEquety?.symbol,
				symbolName: tempEquety?.symbol
			};
			tempSelectedSymbolInfo = symbolItem;

			// } else if (tempSelectedSymbolInfo != undefined && tempSelectedSymbolInfo['full_name'] === symbolName) {
			// 	symbolItem = tempSelectedSymbolInfo; 
		} else {
			const tempSymboleDataList = symbolName.split("#");
			const tempSymbolFromChart = tempSymboleDataList[0];
			if (tempSymboleDataList.length > 1) {
				const msTokenArray = tempSymboleDataList[1].split("_");
				if (msTokenArray.length > 1) {
					const tempMs = msTokenArray[0];
					const tempToken = msTokenArray[1];

					// const scripDetails = await getscripDetailsFromMsT(tempMs, tempToken);
					const scripDetails = await getSecurityInfo(tempMs, tempToken, stockDetails?.authToken, stockDetails?.access_token, stockDetails?.chartUrl);
					if (scripDetails.length > 0) {
						const tempEquety = await findEquetyFromCH(allIndices, scripDetails[0], marketDataRef, stockDetails, scripDetails[0].CH);
						symbolItem = {
							full_name: scripDetails[0].S + "#" + scripDetails[0].MS + "_" + scripDetails[0].T,
							description: scripDetails[0].SD,
							exchange: segmentsNameRef[marketDataRef[scripDetails[0].MS]],
							marketSegment: scripDetails[0].MS,
							token: scripDetails[0].T,
							IC:scripDetails[0].IC,
							api_exchange: tempEquety?.equity,
							user_id: stockDetails.userId,
							// api_exchange: newSymbol.marketExchange,
							// temp_symbole: scripDetails[0].S,
							// symbolName: scripDetails[0].S
							temp_symbole: tempEquety?.symbol,
							symbolName: tempEquety?.symbol
						};
						tempSelectedSymbolInfo = symbolItem;
					}
				}
			}

			if (!symbolItem) {
				const symbols = await getAllSymbols(tempSymbolFromChart, marketDataRef, segmentsNameRef, stockDetails);
				const newSymbols = symbols.filter((symbol) => {
					const isFullSymbolContainsInput = symbol.S + "#" + symbol.MS + "_" + symbol.T === symbolName;
					return isFullSymbolContainsInput;
				});
				if (newSymbols.length > 0) {
					const newSymbol = newSymbols[0];
					const tempEquety = await findEquetyFromCH(allIndices, newSymbol, marketDataRef, stockDetails);

					symbolItem = {
						full_name: newSymbol.S + "#" + newSymbol.MS + "_" + newSymbol.T,
						description: newSymbol.SD,
						exchange: newSymbol.exchange,
						marketSegment: newSymbol.MS,
						token: newSymbol.T,
						IC:tempEquety?.IC,
						api_exchange: tempEquety?.equity,
						user_id: stockDetails.userId,
						chart_url: stockDetails.chartUrl,
						auth_token: stockDetails.authToken,
						MS: stockDetails.MS,
						S: stockDetails.S,
						// api_exchange: newSymbol.marketExchange,
						// temp_symbole: newSymbol.symbol,
						// symbolName: newSymbol.S
						temp_symbole: tempEquety?.symbol,
						symbolName: tempEquety?.symbol
					};
					tempSelectedSymbolInfo = symbolItem;
				}
			}

		}


		if (!symbolItem) {
			onResolveErrorCallback('Symbol not found');
			return;
		}

		const currentTime = new Date();
		const noonTime = new Date();
		noonTime.setHours(14, 0, 0, 0);
		let timePeriod = "0915-1530";
		if (symbolItem.marketSegment == 13) {
			timePeriod = "0900-1700";
		}
		// let timePeriod = "0915-1400";
		// if (currentTime > noonTime) {
		// 	timePeriod = "0915-1530";
		// }
		const symbolInfo = {
			ticker: symbolItem.full_name,
			name: symbolItem.symbolName,
			// description: symbolItem.description,
			description: symbolItem.symbolName,
			session: timePeriod,
			session_holidays: convertHolidayList(symbolItem.marketSegment),
			corrections: getHolidaysWithSession(symbolItem.marketSegment),
			timezone: 'Asia/Kolkata',
			exchange: symbolItem.exchange,
			minmov: 1,
			marketSegment: symbolItem.marketSegment,
			IC:symbolItem?.IC,
			token: symbolItem.token,
			symbolName: symbolItem.symbolName,
			// pricescale: 100,
			pricescale: symbolItem.marketSegment == 13 ? 10000 : 100,
			// has_intraday: true,
			has_intraday: !0,
			type: "stock",
			visible_plots_set: 'ohlcv',
			// has_no_volume: false,
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: 'streaming',
			// intraday_multipliers: configurationData.intraday_multipliers,
			user_id: symbolItem.user_id,
			api_exchange: symbolItem.api_exchange,
			temp_symbol: symbolItem.symbolName,
			temp_ms: symbolItem.marketSegment,
			auth_token: stockDetails.authToken,
			chart_url: stockDetails.chartUrl,
			requestCount: 0,
			temp_symbole: symbolItem.symbolName,
			is_subscribed: false
		};
		onSymbolResolvedCallback(symbolInfo);
	};

	const getBars = async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest } = periodParams;
		let startISO = new Date(from * 1000).toISOString().split("T")[0];
		let endISO = new Date(to * 1000).toISOString().split("T")[0];

		// let s = symbolInfo.ticker;
		// let l = false; 
		// let h = "day";
		// if (s) {
		// 	if (-1 === resolution.indexOf("D") && parseInt(resolution) > 1) {
		// 		h = resolution + "minute";
		// 	} else if (-1 === resolution.indexOf("D") && 1 === parseInt(resolution)) {
		// 		h = "minute";
		// 	} else if (120 === parseInt(resolution)) {
		// 		h = "2hour";
		// 	} else if (180 === parseInt(resolution)) {
		// 		h = "3hour";
		// 	} else if ("1W" === resolution) {
		// 		h = "week";
		// 	} else if ("1M" === resolution) {
		// 		h = "day";
		// 	}
		// 	// console.log("h", h);
		// }

		let s = symbolInfo.ticker;
		let h = "1-MIN";
		if (s) {
			if (-1 === resolution.indexOf("D") && (1 === parseInt(resolution) || 2 === parseInt(resolution) || 3 === parseInt(resolution) || 4 === parseInt(resolution))) {
				h = "1-MIN";
			} else if (-1 === resolution.indexOf("D") && (5 === parseInt(resolution) || 10 === parseInt(resolution) || 15 === parseInt(resolution) || 30 === parseInt(resolution))) {
				h = "5-MIN";
			} else if (-1 === resolution.indexOf("D") && (60 === parseInt(resolution) || 120 === parseInt(resolution) || 180 === parseInt(resolution))) {
				h = "1-HR";
			} else if ("1D" === resolution) {
				h = "1-DAY";
			}
		}

		try {
			const startDate = new Date(from * 1000);
			const toDate = new Date(to * 1000);
			var date = startDate.getFullYear() + '-' + String(startDate.getMonth() + 1).padStart(2, '0') + '-' + String(startDate.getDate()).padStart(2, '0') + ' ' + String(startDate.getHours()).padStart(2, '0') + ':' + String(startDate.getMinutes()).padStart(2, '0') + ':' + String(startDate.getSeconds()).padStart(2, '0');
			var date2 = toDate.getFullYear() + '-' + String(toDate.getMonth() + 1).padStart(2, '0') + '-' + String(toDate.getDate()).padStart(2, '0') + ' ' + String(toDate.getHours()).padStart(2, '0') + ':' + String(toDate.getMinutes()).padStart(2, '0') + ':' + String(toDate.getSeconds()).padStart(2, '0');
			if ("1D" === (resolution)) {
				date = startDate.getFullYear() + '-' + String(startDate.getMonth() + 1).padStart(2, '0') + '-' + String(startDate.getDate()).padStart(2, '0');
				date2 = toDate.getFullYear() + '-' + String(toDate.getMonth() + 1).padStart(2, '0') + '-' + String(toDate.getDate()).padStart(2, '0');
			}


			const chartDataParameters = {
				taskcode: '101',
				entitycode: '591',
				body: {
					EX: symbolInfo.api_exchange,
					T: symbolInfo.token,
					D: h,
					SDT: date,
					EDT: date2,
					S: symbolInfo.name,
					MS: symbolInfo.marketSegment,
					minute_request: false
				},
			};

			const data = await makeApiRequest(chartDataParameters, stockDetails.authToken, stockDetails.chartUrl);

			const bars = [];
			let lastBar;
			if (data.length) {


				data.forEach((bar) => {
					const barDate = bar.DT;
					const [datePart, timePart] = barDate.split(' ');
					const [year, month, day] = datePart.split('-');
					let [hour, minute, second] = [9, 15, 0];
					if (timePart != undefined) {
						[hour, minute, second] = timePart.split(':');
					}
					const dateObj = new Date(year, month - 1, day, hour, minute, second);
					const formattedDate = dateObj.getTime();
					// if (formattedDate >= startDate.getTime() && formattedDate <= toDate.getTime()) {
					const newBar = {
						time: formattedDate,
						low: bar.L,
						high: bar.H,
						open: bar.O,
						close: bar.C,
						volume: Number(bar.V)
					};

					const currentDate = new Date();
					const isCurrentDay = dateObj.getDate() === currentDate.getDate() &&
						dateObj.getMonth() === currentDate.getMonth() &&
						dateObj.getFullYear() === currentDate.getFullYear();

					if (h == "1-MIN" || !isCurrentDay) {
						bars.push(newBar);
						lastBar = newBar;
					}
					// }
				});


				if (firstDataRequest) {
					if (h != "1-MIN") {
						var tempDate = new Date();
						var date = tempDate.getFullYear() + '-' + (String(tempDate.getMonth() + 1).padStart(2, '0')) + '-' + String(tempDate.getDate()).padStart(2, '0');
						tempDate.setDate(tempDate.getDate() + 1);
						var date2 = tempDate.getFullYear() + '-' + (String(tempDate.getMonth() + 1).padStart(2, '0')) + '-' + String(tempDate.getDate()).padStart(2, '0');
						const chartIntraDataParameters = {
							taskcode: "101",
							entitycode: "591",
							body: {
								EX: symbolInfo.api_exchange,
								T: symbolInfo.token,
								D: "1-MIN",
								SDT: date + " 09:00:01",
								EDT: date + " 16:00:00",
								minute_request: false,
								S: symbolInfo.name,
								MS: symbolInfo.marketSegment,
							}
						};

						const intraDayData = await makeApiRequestForIntraday(chartIntraDataParameters, stockDetails.authToken, stockDetails.chartUrl);

						if (intraDayData.length) {
							let openPrice = intraDayData[0]?.O;
							let closePrice = intraDayData[intraDayData.length - 1]?.C;
							let highPrice = intraDayData[0]?.H;
							let lowPrice = intraDayData[0]?.L;
							let volume_full_day = 0;
							intraDayData.forEach((bar) => {
								const barDate = bar.DT;
								const [datePart, timePart] = barDate.split(' ');
								const [year, month, day] = datePart.split('-');
								let [hour, minute, second] = [0, 0, 0];
								if (timePart != undefined) {
									[hour, minute, second] = timePart.split(':');
								}
								const dateObj = new Date(year, month - 1, day, hour, minute, second);
								const formattedDate = dateObj.getTime();
								// if (formattedDate >= startDate.getTime() && formattedDate <= toDate.getTime()) {
								const newBar = {
									time: formattedDate,
									low: bar.L,
									high: bar.H,
									open: bar.O,
									close: bar.C,
									volume: Number(bar.V)
								};
								highPrice = Number(bar.H) > Number(highPrice) ? Number(bar.H) : Number(highPrice);
								lowPrice = Number(bar.L) < Number(lowPrice) ? Number(bar.L) : Number(lowPrice);
								volume_full_day += Number(bar.V);
								if (h != "1-DAY") {
									bars.push(newBar);
								}
								lastBar = newBar;
								// }
							});
							if (h == "1-DAY") {
								const newDate = new Date();
								const formattedDate = newDate.getTime();
								const newBar = {
									time: formattedDate,
									low: lowPrice,
									high: highPrice,
									open: openPrice,
									close: closePrice,
									volume: volume_full_day
								};
								bars.push(newBar);
							}
						}
					}

					const currentTime = new Date();
					const lastTempBar = bars[bars.length - 1];
					const seconds = currentTime.getSeconds();

					const tempTime = lastTempBar.time - seconds * 1000;
					lastTempBar.tempTime = tempTime;
					lastBarsCache.set(symbolInfo.full_name, {
						...lastTempBar,
					});
				}
				symbolInfo.requestCount = 0;
				onHistoryCallback(bars, { noData: false });


			} else if ((new Date(startISO) - new Date(endISO)) / 864e5 < 4 && symbolInfo.requestCount < 5) {
				let nextStartTime = new Date(new Date(endISO).getTime() - 864e5);

				symbolInfo.requestCount += 1;
				onHistoryCallback([], { nextTime: Math.floor(nextStartTime.getTime() / 1e3) });
				// return;
			} else {
				symbolInfo.requestCount = 0;
				onHistoryCallback([], { noData: true });
				// return;
			}

			// lastBarsCache.set(symbolInfo.full_name, lastBar);


		} catch (error) {
			onErrorCallback(error);
		}
	};

	const getMarks = async (symbolInfo, startDate, endDate, onDataCallback, resolution) => { 
 
		const from = new Date(startDate * 1000);
		const toDate = new Date(endDate * 1000);
		const date = from.getFullYear() + '-' + String(from.getMonth() + 1).padStart(2, '0') + '-' + String(from.getDate()).padStart(2, '0');
		const date2 = toDate.getFullYear() + '-' + String(toDate.getMonth() + 1).padStart(2, '0') + '-' + String(toDate.getDate()).padStart(2, '0');
 
		if(symbolInfo.IC){
			const corporateActionParams = {
				taskcode: "100",
				entitycode: "580",
				body: {
					module: "CORPORATEACTIONSCHART",
					submodule: "ALL",
					pagesize: "",
					isincode: symbolInfo.IC,
					symbol: "",
					fromDate: date,
					toDate: date2
				}
			};
			const corporateActions = await makeApiRequestForCorporateActions(corporateActionParams,symbolInfo?.auth_token,symbolInfo?.chart_url);
	
			const marks = corporateActions.map(item => ({
				...item,
				time: Math.floor(new Date(item.time).getTime() / 1000)
			})); 
			onDataCallback(marks);
		}

	};


	const subscribeBars = (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {

		const subscribedTokenData = JSON.parse(localStorage.getItem("subscribed_tokens")) || {};
		subscribedTokenData[subscriberUID] = symbolInfo.marketSegment + "_" + symbolInfo.token;
		localStorage.setItem("subscribed_tokens", JSON.stringify(subscribedTokenData));

		// if (!symbolInfo.is_subscribed || true) {
		// 	symbolInfo.is_subscribed = true;
			subscribeOnStream(
				symbolInfo,
				resolution,
				onRealtimeCallback,
				subscriberUID,
				onResetCacheNeededCallback,
				lastBarsCache.get(symbolInfo.full_name)
			);
		// }
	};

	const unsubscribeBars = (subscriberUID) => {
		unsubscribeFromStream(subscriberUID);
	};

	const findEquetyFromCH = async (allIndices, symbolInfo, marketDataRef, stockDetails, defaultCh = '') => {
		let tempEquity = marketDataRef[symbolInfo?.MS];
		let tempSymbole = symbolInfo?.S;
		let tempCh = defaultCh;
		let IC = "";
		if (!defaultCh) {
			const scripDetails = await getSecurityInfo(symbolInfo?.MS, symbolInfo?.T, stockDetails?.authToken, stockDetails?.access_token, stockDetails?.chartUrl);
			if (scripDetails.length > 0 && scripDetails[0]?.IC) {
				IC = scripDetails[0]?.IC;
			}
			if (scripDetails.length > 0 && scripDetails[0]?.CH) {
				tempCh = scripDetails[0]?.CH;
				tempSymbole = scripDetails[0]?.CH;
			} else {
				tempCh = symbolInfo?.S;
			}
		} else {
			tempSymbole = defaultCh;
		}

		const isValueAvailablePart = allIndices.includes(tempCh);
		// const isValueAvailablePart = allIndices.includes(symbolInfo?.S);

		const market_segment = symbolInfo?.MS;
		if (!isValueAvailablePart) {
			if (market_segment == 1) {
				tempEquity = "NSE_EQ";
			} else if (market_segment == 2) {
				tempEquity = "NSE_FO";
			} else if (market_segment == 3) {
				tempEquity = "BSE_EQ";
			}
		} else {
			if (market_segment == 1) {
				tempEquity = "NSE_IDX";
			} else if (market_segment == 3) {
				tempEquity = "BSE_IDX";
			}
		}
		if (market_segment == 13) {
			tempEquity = "NSE_CUR";
		}

		return {
			equity: tempEquity,
			symbol: tempSymbole,
			IC:IC
		};
	}

	return {
		onReady,
		resolveSymbol,
		getBars,
		subscribeBars,
		unsubscribeBars,
		searchSymbols,
		getMarks
	};
}
