// import { apiCall } from "app/services/apiService/api";

import axios from "axios";

// Makes requests to CryptoCompare API
export async function makeApiRequest(parameters, authToken, chartUrl) {
	try {
		let chart_url = chartUrl;
		// if (parameters.body.D == "1-DAY") {
		chart_url = chart_url.replace("stxmiddlewareapi", "stxchartapi");
		// }
		try {
			const result = await axios.post(chart_url, parameters,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + authToken,
					},
				}
			);
			if (parameters?.body?.minute_request) {
				if (result.status == 200 && typeof result.data.status != 'undefined' && result.data.status == "success" && result.data.data != [] && result.data.data.length > 0) {
					return result.data.data;
				} else {
					return [];
				}
			}
			try {
				if (result.status == 200 && typeof result.data.status != 'undefined' && result.data.status == "success") {
					const response = JSON.parse(result.data.data);

					if (typeof response?.data !== "undefined" && response?.data != [] && response?.data?.length > 0) {
						return response?.data;
					} else if (response != [] && response.length > 0) {
						return response;
					}
					return [];

				} else {
					return [];
				}
			} catch (error) {
				if (result.status == 200 && typeof result.data.status != 'undefined' && result.data.status == "success" && result.data.data != [] && result.data.data.length > 0) {
					return result.data.data;
				} else {
					return [];
				}
			}

		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}


export async function makeApiRequestForIntraday(parameters, authToken, chartUrl) {
	try {
		try {
			const chart_url = chartUrl.replace("stxmiddlewareapi", "stxchartapi");

			const result = await axios.post(chart_url, parameters,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + authToken,
					},
				}
			);
			if (parameters?.body?.minute_request) {
				if (result.status == 200 && typeof result.data.status != 'undefined' && result.data.status == "success" && result.data.data != [] && result.data.data.length > 0) {
					return result.data.data;
				} else {
					return [];
				}
			}
			try {
				if (result.status == 200 && typeof result.data.status != 'undefined' && result.data.status == "success") {
					const response = JSON.parse(result.data.data);

					if (typeof response?.data !== "undefined" && response?.data != [] && response?.data?.length > 0) {
						return response?.data;
					} else if (response != [] && response.length > 0) {
						return response;
					}
					return [];

				} else {
					return [];
				}
			} catch (error) {
				if (result.status == 200 && typeof result.data.status != 'undefined' && result.data.status == "success" && result.data.data != [] && result.data.data.length > 0) {
					return result.data.data;
				} else {
					return [];
				}
			}

		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}


export async function getSavedTemplate(authToken, chartUrl) {
	try {
		try {
			const url = new URL(chartUrl);
			const baseURL = `${url.protocol}//${url.host}/`;
			const parameters = {
				"taskcode": "100",
				"entitycode": "605",
				"body": {

				}
			};
			const getTemplateUrl = baseURL + "stxchartapi/execute";
			const result = await axios.post(getTemplateUrl, parameters,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + authToken,
					},
				}
			);
			if (result.status == 200 && result.data) {
				return result.data;
			}
			return null;

		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		console.error('Error:', error);
	}
}


export async function getSavedIndicators(authToken, chartUrl) {
	try {
		try {
			const url = new URL(chartUrl);
			const baseURL = `${url.protocol}//${url.host}/`;
			const parameters = {
				"taskcode": "100",
				"entitycode": "612",
				"body": {

				}
			};
			const getTemplateUrl = baseURL + "stxchartapi/execute";
			const result = await axios.post(getTemplateUrl, parameters,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + authToken,
					},
				}
			);
			if (result.status == 200 && result.data) {
				return result.data;
			}
			return null;

		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		console.error('Error:', error);
	}
}



export async function saveTemplate(data, authToken, chartUrl) {
	try {
		try {
			const url = new URL(chartUrl);
			const baseURL = `${url.protocol}//${url.host}/`;
			const parameters = new FormData();
			parameters.append('chartTemplate', new Blob([JSON.stringify(data)], { type: 'application/json' }));

			const uploadTemplateUrl = baseURL + "stxchartapi/uploadfile";
			const result = await axios.post(uploadTemplateUrl, parameters,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: 'Bearer ' + authToken,
					},
				}
			);
			if (result.status == 200 && result.data) {
				return "Template saved successfully.";
			}
			return null;
		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		console.error('Error:', error);
	}
}


export async function saveIndicators(data, authToken, chartUrl) {
	try {
		try {
			const url = new URL(chartUrl);
			const baseURL = `${url.protocol}//${url.host}/`;
			const parameters = new FormData();
			parameters.append('chartindicator', new Blob([JSON.stringify(data)], { type: 'application/json' }));

			const uploadTemplateUrl = baseURL + "stxchartapi/uploadindicatorfile";
			const result = await axios.post(uploadTemplateUrl, parameters,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: 'Bearer ' + authToken,
					},
				}
			);
			if (result.status == 200 && result.data) {
				return "Indicators saved successfully.";
			}
			return null;
		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		console.error('Error:', error);
	}
}



// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

export async function getSecurityInfo(marketSegment, token, authToken, access_token, chartUrl) {
	try {
		try {
			const url = new URL(chartUrl);
			const baseURL = `${url.protocol}//${url.host}/`;
			// const parameters = {
			// 	taskname: 'Security Info',
			// 	vendoromstype: '1',
			// 	tenantid: '##SBTENANTID##',
			// 	mktSegmentId: api_parameters.MS,
			// 	token: api_parameters.T,
			// };

			const parameters = {
				"taskcode": "100",
				"entitycode": "589",
				"body": [{
					"MS": marketSegment,
					"T": token
				}],
				"taskname": "Scrip Name",
				"vendoromstype": "1",
			};

			const headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + authToken,
			};

			if (access_token) {
				headers['access-token'] = 'Bearer ' + access_token;
			}
			const getSecurityInfoUrl = baseURL + "stxauth/authentication";
			const result = await axios.post(getSecurityInfoUrl, parameters,
				{
					headers: headers,
				}
			);
			if (result.status == 200 && result.data && result.data?.length > 0) {
				return result.data;
			}
			return null;

		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		console.error('Error:', error);
	}
}


export async function getAllMarketIndices(authToken, access_token, chartUrl) {
	try {
		try {
			const url = new URL(chartUrl);
			const baseURL = `${url.protocol}//${url.host}/`;
			const parameters = {
				"taskcode": "100",
				"entitycode": "601",
				"body": {}
			};
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + authToken,
			};

			if (access_token) {
				headers['access-token'] = 'Bearer ' + access_token;
			}
			const getSecurityInfoUrl = baseURL + "stxauth/authentication";
			const result = await axios.post(getSecurityInfoUrl, parameters,
				{
					headers: headers,
				}
			);
			if (result.status == 200 && result.data && result?.data?.data?.length > 0) {
				return result?.data?.data;
			}
			return null;

		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		console.error('Error:', error);
	}
}


export async function getSymbolFromAPI(text = "", authToken, chartUrl) {
	try {
		try {
			var tempSearchText = text.replace(/%/g, "____").replace(/&/g, "___");
			const url = new URL(chartUrl);
			const baseURL = `${url.protocol}//${url.host}/`;
			const getTemplateUrl = baseURL + "stxauthapi/search?query=" + tempSearchText + "&count=1000";
			const result = await axios.get(getTemplateUrl,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + authToken,
					},
				}
			);
			if (result.status == 200 && result.data.length > 0) {
				return result.data;
			}
			return [];

		} catch (error) {
			console.error('Error:', error);
			return [];
		}

	} catch (error) {
		console.error('Error:', error);
		return [];
	}
}

export async function makeApiRequestForCorporateActions(parameters, authToken, chartUrl) {
	try {
		try {

			const url = new URL(chartUrl);
			const baseURL = `${url.protocol}//${url.host}/`;

			const headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + authToken,
			};

			const getCorporateActionUrl = baseURL + "stxauth/authentication";
			const result = await axios.post(getCorporateActionUrl, parameters,
				{
					headers: headers,
				}
			); try {
				if (result?.status == 200 && result?.data?.length > 0) {
					return result.data;
				} else {
					return [];
				}
			} catch (error) {
				return [];
			}

		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}
