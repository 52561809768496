import { ConnectSocket, SendMessageOnSocket } from "./channel";

var sIP, sPort, sMode;


const socketOnMessage1 = () => async (msg) => {
  try {
    // Delay the processing of each message using a setTimeout
    await new Promise((resolve) => setTimeout(resolve, 0));

    // Process the socket message
    await OnSocketDataReceive(msg);
  } catch (ex) {
    console.error("Error occurred while processing socket data.", ex);
  }
};
const SocktConnect = (socketUser) => {
  sIP = "wave.bpwealth.com";
  sPort = "4511";
  sMode = "wss";

  const socketOnMessage = socketOnMessage1();

  ConnectSocket(sIP, sPort, sMode, socketUser, socketOnMessage);
};

const Subscribe = (stockTokens) => {
  handleSubscription(stockTokens, 1);
};
const Unsubscribe = (stockTokens) => {
  handleSubscription(stockTokens, 2);
};


function handleSubscription(stockTokens, isSubscribe) {
  try {

    var sMsgToSend = "63=FT3.0|64=206|65=84|66=19:02:31|4=|";

    for (var i = 0; i < stockTokens.length; i++) {
      sMsgToSend += "1=" + (stockTokens[i].split('_')[0]) + "$7=" + stockTokens[i].split('_')[1] + "|";
    }

    sMsgToSend += "230=1";
    // console.log('sMsgToSend :',sMsgToSend);
    SendMessageOnSocket(sMsgToSend);


  } catch (ex) {
    console.log(
      "Error occurred while sending message on socket. Reason : " +
      ex.message +
      " | Stack trace : " +
      ex.stack
    );
  }
}


export { SocktConnect, Subscribe, Unsubscribe };

