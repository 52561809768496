// import { Subscribe } from 'app/main/watch-list/watchListSocket.js'; 
import { SocktConnect, Subscribe } from '../socket/index.js';
import { chartSubscribe, chartUnsubscribe, subscribe, unsubscribe } from './dataStream.js';
import { makeApiRequest } from './helpers.js';

let subscription = {};
// function getNextDailyBarTime(barTime) {
// 	const date = new Date(barTime * 1000);
// 	date.setDate(date.getDate() + 1);
// 	return date.getTime() / 1000;
// }


function getNextDailyBarTime(barTime) {
	const date = new Date(barTime);
	date.setSeconds(0);
	date.setMilliseconds(0);
	date.setMinutes(date.getMinutes() + 1);
	return date.getTime();
}



export function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscriberUID,
	onResetCacheNeededCallback,
	lastDailyBar,
	// lastBarsCache
) {
	let subscriptionItem;

	const isApiCallAllowed = () => {
		const currentTime = new Date();
		const startHour = 9;
		let startMinute = 15;
		let endHour = 15;
		let endMinute = 45;
		if (symbolInfo.marketSegment == 13) {
			startMinute = 0;
			endHour = 17;
			endMinute = 0;
		}
		const currentHour = currentTime.getHours();
		const currentMinute = currentTime.getMinutes();
		return (
			(currentHour > startHour ||
				(currentHour === startHour && currentMinute >= startMinute)) &&
			(currentHour < endHour ||
				(currentHour === endHour && currentMinute <= endMinute))
		);
	}

	// SocktConnect(symbolInfo.user_id);
	setTimeout(() => {
		Subscribe([symbolInfo.marketSegment + "_" + symbolInfo.token]);
	}, 1000);

	const handleInternetConnectionChange = (event) => {
		if (event.type === 'online') {
			// setGlobalState("internetCheckStatus", false);
			SocktConnect(symbolInfo.user_id);
			setTimeout(() => {
				Subscribe([symbolInfo.marketSegment + "_" + symbolInfo.token]);
			}, 3000);
		} else if (event.type === 'offline') {
			console.log("offline");
			// setGlobalState("internetCheckStatus", true);
		}
	};
	// Add event listeners
	window.addEventListener('online', handleInternetConnectionChange);
	window.addEventListener('offline', handleInternetConnectionChange);


	subscriptionItem = {
		subscriberUID,
		resolution,
		lastDailyBar,
		handlers: [{
			id: subscriberUID,
			callback: onRealtimeCallback,
		}],
		tempBar: lastDailyBar,
		isApiCalls: true
	};


	try {

		const key = symbolInfo.marketSegment + "_" + symbolInfo.token+"@@"+subscriberUID;
		const tempSubscription = chartSubscribe(key, (data) => {
			// console.log(`Received data in receiver.js for ${key}:`, data);
			const dateTimeString = data.DT;
			const [dateString, timeString] = dateTimeString.split(' ');
			const formattedDateTimeString = `${dateString.replace(/-/g, '/')} ${timeString.slice(0, 2)}:${timeString.slice(2, 4)}:${timeString.slice(4, 6)}`;
			const dateObj = new Date(formattedDateTimeString);
			const tradeTime = dateObj.getTime();
			const currentStreamDate = dateObj.toDateString();
			const lastDailyBar = subscriptionItem.lastDailyBar;
			const lastDailyBarDate = new Date(lastDailyBar?.time).toDateString();
			if (!lastDailyBar) {
				return true;
			}
			const currentDate = new Date();
			currentDate.setHours(9);
			currentDate.setMinutes(15);
			currentDate.setSeconds(0);
			currentDate.setMilliseconds(0);
			const currentDayStartTime = currentDate.getTime();
			const nextDailyBarTime = currentStreamDate != lastDailyBarDate ? currentDayStartTime : getNextDailyBarTime(lastDailyBar?.time);
			// const nextDailyBarTime = getNextDailyBarTime(lastDailyBar?.time);
			let bar;

			const formatDate = (date) => {
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				// const seconds = String(date.getSeconds()).padStart(2, '0');
				const seconds = ("0").padStart(2, '0');

				return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			};
			if (tradeTime >= (nextDailyBarTime + 5000) && subscriptionItem.isApiCalls && isApiCallAllowed() && resolution != '1D') {
				subscriptionItem.isApiCalls = false;
				const nextApiTime = formatDate(new Date(nextDailyBarTime));
				const chartDataParameters = {
					taskcode: '101',
					entitycode: '591',
					body: {
						EX: symbolInfo.api_exchange,
						T: symbolInfo.token,
						D: '1-MIN',
						SDT: nextApiTime,
						EDT: nextApiTime,
						S: symbolInfo.temp_symbole,
						MS: symbolInfo.marketSegment,
						minute_request: true
					},
				};
				const apiData = makeApiRequest(chartDataParameters, symbolInfo.auth_token, symbolInfo.chart_url);
				apiData.then((apiData) => {

					if (apiData.length > 0) {
						const tempBar = apiData[apiData.length - 1];
						const dateObj = new Date(nextDailyBarTime);
						// const dateObj = new Date(tempBar.DT);
						const formattedDate = dateObj.getTime();
						const tempBarOld = {
							...subscriptionItem.tempBar,
							isBarClosed: true,
							isLastBar: false,
							low: tempBar.L,
							high: tempBar.H,
							open: tempBar.O,
							close: tempBar.C,
							volume: Number(tempBar.V),
						};


						subscriptionItem.lastDailyBar = tempBarOld;
						subscriptionItem.handlers.forEach(handler => handler.callback(tempBarOld));
						// bar = {
						// 	time: formattedDate,
						// 	low: tempBar.L,
						// 	high: tempBar.H,
						// 	open: tempBar.O,
						// 	close: tempBar.C,
						// 	volume: Number(tempBar.V),
						// };
						bar = {
							time: formattedDate,
							open: lastDailyBar.close,
							// high: Math.max(Number(lastDailyBar.high).toFixed(2), Number(data.LTP)),
							// low: Math.min(Number(lastDailyBar.low).toFixed(2), Number(data.LTP)),
							high: Number(data.LTP),
							low: Number(data.LTP),
							close: Number(data.LTP),
							volume: Number((Number(data.V) / 100)),
						};

						subscriptionItem.lastDailyBar = bar;
						subscriptionItem.tempBar = bar;
						// lastBarsCache.set(symbolInfo.full_name, {
						// 	...bar,
						// });
						subscriptionItem.handlers.forEach(handler => handler.callback(bar));
						setTimeout(() => {
							subscriptionItem.isApiCalls = true;
						}, 1000);
					} else {
						// bar = lastDailyBar;
						bar = {
							...lastDailyBar,
							high: Math.max(Number(lastDailyBar.high), Number(data.LTP)),
							low: Math.min(Number(lastDailyBar.low), Number(data.LTP)),
							close: Number(data.LTP),
							volume: Number((Number(data.V) / 100)),
						};
						subscriptionItem.lastDailyBar = bar;
						subscriptionItem.handlers.forEach(handler => handler.callback(bar));
						setTimeout(() => {
							subscriptionItem.isApiCalls = true;
						}, 1000);
					}

				}).catch((error) => {
					bar = lastDailyBar;
					subscriptionItem.lastDailyBar = bar;
					// Send data to every subscriber of that symbol
					subscriptionItem.handlers.forEach(handler => handler.callback(bar));
					subscriptionItem.isApiCalls = true;
				});

			} else {
				bar = {
					...lastDailyBar,
					high: Math.max(Number(lastDailyBar.high), Number(data.LTP)),
					low: Math.min(Number(lastDailyBar.low), Number(data.LTP)),
					close: Number(data.LTP),
					volume: resolution != '1D' ? Number((Number(data.V) / 100)) : Number(data.V),
					// isBarClosed:false,
					// isLastBar:true0
				};
				subscriptionItem.lastDailyBar = bar;
				// Send data to every subscriber of that symbol
				subscriptionItem.handlers.forEach(handler => handler.callback(bar));
				// console.log('[socket] Update the latest bar by price', Number((data.LTP)/100).toFixed(2));
			}

		});
		subscription[key] = tempSubscription;
	} catch (error) {
		console.log("chart error from socket:::", error);
	}

	// console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
}


export function unsubscribeFromStream(subscriberUID) {
	// Unsubscribe when done
	// subscription.unsubscribe();
	const subscribedTokenData = JSON.parse(localStorage.getItem("subscribed_tokens")) || {};
	// const userInformation = useGlobalState("userInformation")[0];
	const subscribed_token = subscribedTokenData[subscriberUID];
	if (subscribed_token !== undefined) {
		delete subscribedTokenData[subscriberUID];
		localStorage.setItem("subscribed_tokens", JSON.stringify(subscribedTokenData));
	}
	if (subscription != null && subscription != 'null' && subscription != '') {
		chartUnsubscribe(subscribed_token+"@@"+subscriberUID, subscription[subscribed_token+"@@"+subscriberUID]);
	}
	// console.log("subscription", subscription);
	// console.log("subscribed_token", subscribed_token);
	// console.log("subscribedTokenData",subscription[subscribed_token]);
	// console.log("unsubscribe", subscriberUID);
}	
